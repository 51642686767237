<template>
  <Select
    v-model="selectModel"
    ref="select"
    title="Кем выдан"
    label="value"
    itemsKey="value"
    :error="error"
    @input="input"
    @listClick="listClick"
    :list="list"
  />
</template>

<script>
import Select from "@index/components/common/select/Select";

import axios from "axios";

const DADATA_URL = process.env.VUE_APP_DADATA_URL + "fms_unit";
const TOKEN = process.env.VUE_APP_DADATA_TOKEN;

export default {
  name: "FMSSearch",
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    model: {
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    selectModel: {
      get() {
        return {
          value: this.model,
        };
      },
      set(v) {
        if (!v)
          v = {
            value: "",
          };

        this.$emit("change", v.value);
      },
    },
    select() {
      return this.$refs.select;
    },
    field() {
      return this.select.field;
    },
  },
  methods: {
    listClick() {
      setTimeout(() => {
        this.select.isFocus = false;
        this.field.blur();
      });
    },
    getList(v) {
      if (!v) {
        this.list = [];
        return;
      }

      const data = {
        count: 5,
        restrict_value: true,
        query: v,
      };

      axios
        .post(DADATA_URL, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${TOKEN}`,
          },
        })
        .finally(() => {
          this.showLoading = 0;
        })
        .then((response) => {
          if (
            response.data &&
            response.data.suggestions &&
            response.data.suggestions.length > 0
          ) {
            this.list = response.data.suggestions;
          } else {
            this.list = [];
          }
        });
    },
    input(v) {
      this.selectModel = {
        value: v,
      };
    },
  },
  components: {
    Select,
  },
};
</script>
