<template>
  <div class="passport-information">
    <div class="passport-information__line">
      <Address
        title="Адрес регистрации"
        v-model="form.contactData.addrcity"
        :error="formErrors.contactData.addrcity"
      />
    </div>
    <div class="passport-information__combined">
      <Field
        v-model="form.passportData.passportseries"
        :error="formErrors.passportData.passportseries"
        title="Серия"
        mask="## ##"
        autoTab="number"
        valueWithoutMask
      />
      <Field
        v-model="form.passportData.passportnumber"
        :error="formErrors.passportData.passportnumber"
        title="Номер"
        name="number"
        autoTab="issuecode"
        mask="### ###"
        valueWithoutMask
      />
      <Field
        v-model="form.passportData.passportissuecode"
        :error="formErrors.passportData.passportissuecode"
        title="Код подразделения"
        name="issuecode"
        autoTab="issuer"
        mask="###-###"
        valueWithoutMask
      />
    </div>
    <div class="passport-information__issuer">
      <FMS
        title="Кем выдан"
        v-model="form.passportData.passportissuer"
        ref="FMS"
        :error="formErrors.passportData.passportissuer"
        name="issuer"
      />
      <Date
        title="Дата выдачи"
        v-model="form.passportData.passportissuedate"
        :error="formErrors.passportData.passportissuedate"
      />
    </div>
  </div>
</template>

<script>
import "./passport-information.scss";
import Field from "@index/components/common/field/Field";
import Date from "@index/components/common/field/Date";
import Address from "@index/components/application/search/Address";
import FMS from "@index/components/application/search/FMS";

import validation from "@index/mixins/validation";
import Validation from "@index/ext/validation/Validation";

import { year, month, day } from "@index/helpers/date/currentDate";
const TODAY = `${day}.${month}.${year}`;

const ISSUE_DATE_VALIDATE = [Validation.DATE, [Validation.DATE_LESS, TODAY]];

import { mapGetters } from "vuex";

export default {
  name: "PassportInformation",
  mixins: [validation],
  data() {
    return {
      form: {
        contactData: {
          addrcity: "",
          birthday: "",
        },
        passportData: {
          passportissuecode: "",
          passportnumber: "",
          passportseries: "",
          passportissuer: "",
          passportissuedate: "",
        },
      },
      formRules: {
        contactData: {
          addrcity: [Validation.REQUIRED],
        },
        passportData: {
          passportissuecode: [
            Validation.REQUIRED,
            [Validation.MIN, 6],
            [Validation.MAX, 6],
          ],
          passportnumber: [
            Validation.REQUIRED,
            [Validation.MIN, 6],
            [Validation.MAX, 6],
          ],
          passportseries: [
            Validation.REQUIRED,
            [Validation.MIN, 4],
            [Validation.MAX, 4],
          ],
          passportissuer: [Validation.REQUIRED],
          passportissuedate: ISSUE_DATE_VALIDATE,
        },
      },
      customErrors: {
        passportData: {
          passportissuedate: {
            [Validation.DATE_LESS]: "Дата не может быть больше текущей",
            [Validation.DATE_MORE]: "Некорректная дата выдачи паспорта",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "application/user",
    }),
  },
  watch: {
    "form.contactData.birthday"(v) {
      if (!v) return;

      const passportRules = this.formRules.passportData;
      const [birthDay, birthMonth, birthYear] = v.split(".").map((i) => +i);

      let age = year - birthYear;
      if (birthMonth > month || (birthMonth === month && birthDay > day)) age--;

      if (!birthYear || birthYear.length < 4)
        passportRules.passportissuedate = ISSUE_DATE_VALIDATE;
      else if (age >= 45)
        passportRules.passportissuedate = [
          ...ISSUE_DATE_VALIDATE,
          [Validation.DATE_MORE, `${birthDay}.${birthMonth}.${birthYear + 45}`],
        ];
      else if (age >= 20)
        passportRules.passportissuedate = [
          ...ISSUE_DATE_VALIDATE,
          [Validation.DATE_MORE, `${birthDay}.${birthMonth}.${birthYear + 20}`],
        ];
      else if (age >= 18)
        passportRules.passportissuedate = [
          ...ISSUE_DATE_VALIDATE,
          [Validation.DATE_MORE, `${birthDay}.${birthMonth}.${birthYear + 14}`],
        ];

      this.formIsSubmit && this.validate(false);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.form.contactData.addrcity = this.user.contactData.addrcity;
      this.form.passportData.passportissuecode =
        this.user.passportData.passportissuecode;
      this.form.passportData.passportnumber =
        this.user.passportData.passportnumber;
      this.form.passportData.passportseries =
        this.user.passportData.passportseries;
      this.form.passportData.passportissuedate =
        this.user.passportData.passportissuedate;
      this.form.passportData.passportissuer =
        this.user.passportData.passportissuer;
      this.form.birthday = this.user.contactData.birthday;

      this.$nextTick(() => {
        this.$watch("form.passportData.passportissuecode", (v) => {
          if (!v) return;

          const FMS = this.$refs.FMS;

          if (v.length < 6 || !FMS) return;

          FMS.getList(v);
        });
      });
    },
  },
  components: {
    Field,
    Address,
    Date,
    FMS,
  },
};
</script>
