<script>
import props from "./mixins/commonProps";
import { year } from "@index/helpers/date/currentDate";

let { props: templateProps } = props;
templateProps = Object.keys(templateProps).map((prop) => `:${prop}="${prop}"`);

const template = `
    <Field v-model="date"
           ref="model"
           :checkMaskPosition="checkMaskPosition"
           ${templateProps.join(" ")}
           mask="##.##.####"/>
`;

export default {
  name: "DateField",
  components: {
    Field: () => import("./Field"),
  },
  mixins: [props],
  props: {
    minYears: {
      type: Number,
      default: 100,
    },
    maxYears: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  computed: {
    minYear() {
      return String(year - this.minYears);
    },
    maxYear() {
      return String(year - this.maxYears);
    },
    date: {
      get() {
        if (!this.model) return "";

        return this.model;
      },
      set(v) {
        let [day = "", month = "", year = ""] = v
          .split(".")
          .map((item, index) => {
            const itemLength = item.length;

            switch (index) {
              case 0: {
                if ([1, 2, 3].includes(+item)) break;

                if (+item === 0) {
                  if (itemLength === 2) item = "01";
                } else if (itemLength === 1) {
                  item = `0${item}`;
                } else if (+item > 31) {
                  item = "31";
                }

                break;
              }
              case 1: {
                if (+item === 1) break;

                if (itemLength === 1 && +item !== 0) item = `0${item}`;
                else if (+item > 12) item = "12";
                else if (itemLength === 2 && +item === 0) item = "01";

                break;
              }
              case 2: {
                const minYear = +this.minYear.substr(0, itemLength);
                const maxYear = +this.maxYear.substr(0, itemLength);

                if (+item < minYear) item = minYear;
                else if (+item > maxYear) item = maxYear;
              }
            }

            return item;
          });

        const date = [day, month, year].filter((item) => item).join(".");

        this.$emit("change", date);
      },
    },
  },
  methods: {
    checkMaskPosition(oldVal, newVal) {
      return newVal.length;
    },
  },
  template,
};
</script>
